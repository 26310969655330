.program-list {
  display: inline-block;
  margin-left: 1215px;
  width: 510px;
  top: 30px;
  left: 0;
  list-style-type: none;
}

.program-list ul li:hover {
  background: gray;
}
.program-list-wrapper {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.program-list-wrapper ul {
  list-style-type: none;
  width: 100%;
}

.program-list-wrapper ul li {
  font-size: 24px;
  line-height: 50px;
  padding-left: 20px;
  align-items: center;
}

.program-list-body {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  text-align: left;
  margin: 0;
  padding: 0;
  top: -31px;
}

.program-list-text {
  line-height: 30px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  padding-left: 16px;
  margin: 0;
}

.program-list-inner {
  width: 100%;
  padding-left: 0;
  margin: 0;
  top: 0;
}

.program-list-inner li {
  display: flex;
}

.program-list-inner li h4 {
  margin: 0 15px 0 0;
  padding: 0;
  font-size: 20px;
  font-weight: 100;
  color: white;
}
.program-list-inner li abbr {
  margin: 0 0 0 15px;
}