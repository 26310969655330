.channel-list {
  display: inline-block;
  margin-left: 693px;
  width: 510px;
  top: 30px;
  left: 0;
  list-style-type: none;
}

.channel-list ul li:hover {
  background: gray;
}

.channel-list-wrapper ul {
  list-style-type: none;
  width: 100%;
}
.channel-list-wrapper {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.channel-list-wrapper ul li {
  font-size: 30px;
  line-height: 50px;
  padding-left: 20px;
}

.channel-list-body {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  text-align: left;
  margin: 0;
  padding: 0;
  top: -31px;
}

.channel-list-text {
  line-height: 30px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  padding-left: 16px;
  margin: 0;
}

.channel-list-inner {
  width: 100%;
  padding-left: 0;
  margin: 0;
  top: 0;
}
.channel-list-inner h3 {
  display: flex;
  align-items: center;
  color: #aaa;
  letter-spacing: 1px;
  margin: 0;
  font-weight: 400;
}
.channel-list-inner h3::after, .channel-list-inner h3::before {
  flex-grow: 1;
  content: "";
  height: 1px;
  background-color: #51565c;
  margin: 0 15px;

}
.channel-list-inner li {
  display: flex;
}

.channel-list-inner li h4 {
  margin: 0 15px 0 0;
  padding: 0;
  font-size: 20px;
  font-weight: 100;
  color: #51565c;
}
.channel-list-inner li abbr {
  margin: 0;
}
