.weather-list {
  display: inline-block;
  margin-left: 322px;
  width: 510px;
  top: 30px;
  left: 0;
  list-style-type: none;
}

.weather-list ul li:hover {
  background: gray;
}

.weather-list-wrapper ul {
  list-style-type: none;
  width: 100%;
}

.weather-list-wrapper {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.weather-list-wrapper ul li {
  line-height: 50px;
  width: 100%;
  display: flex;
  padding-left: 15px;
  font-size: 15px;
  margin: 0;
  align-items: center;
}

.weather-list-wrapper ul li h2 {
  width: 60%;
  display: flex;
  padding: 0;
  align-items: center;
  margin: 0;
}

.weather-list-wrapper ul li h2 {
  display: flex;
  padding: 0;
  align-items: center;
  margin: 0;
}

.weather-list-wrapper ul li h3 {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  font-size: 25px;
}

.weather-list-wrapper ul li h3 h6 {
  display: flex;
  margin: 0;
  padding-right: 20px;
}

.weather-list-wrapper ul li h5 {
  display: flex;
  margin: 0;
  padding-left: 15px;
  font-size: 25px;
  color: #51565c;
}

.weather-list-body {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  text-align: left;
  margin: 0;
  padding: 0;
  top: -31px;
}

.weather-list-text {
  line-height: 30px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  padding-left: 16px;
  margin: 0;
}

.weather-list-inner {
  width: 100%;
  padding-left: 0;
  margin: 0;
  top: 0;
}

.weather-list-inner span {
  display: flex;
  width: 100%;
  padding: 10px 0 10px 15px;
}

.weather-list-inner span h3 {
  width: 40%;
  right: 0;
  font-size: 25px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.weather-list-inner span h3 h6 {
  margin: 0;
  padding-right: 10px;
}

.weather-list-inner span h2 {
  width: 50%;
  margin: 0;
  right: 0;
  font-size: 30px;
}

.weather-list-inner h4 {
  display: flex;
  align-items: center;
  color: #51565c;
  letter-spacing: 1px;
  margin: 0;
  font-weight: 400;
}

.weather-list-inner h4::after, .weather-list-inner h4::before {
  flex-grow: 1;
  content: "";
  height: 1px;
  background-color: #51565c;
  margin: 0 15px;

}

.weather-list-inner li {
  display: flex;
}

.weather-list-inner li h4 {
  margin: 0 15px 0 0;
  padding: 0;
  font-size: 20px;
  font-weight: 100;
  color: #51565c;
}

.weather-list-inner li abbr {
  margin: 0;
}
