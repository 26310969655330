.menu-wrapper {
  position: absolute;
  top: 20px;
  left: 0px;
}

.container {
  width: 2980px;
  height: 400px;
  overflow-x: scroll;
  position: absolute;
  top: 0;
  left: 0;
}

.active {
  background: #fa5c00;
}

.selected {
  background: gray;
}
