
.list-fon {
  position: absolute;
  top: 30px;
  color: white;
  width: 300px;
  height: 432px;
  left: 10px;
  background-color: #000;
  border-radius: 8px;
  border: 1px solid #6B6B6B;
  padding-top: 10px;
  font-family: "San Francisco", sans-serif;
}

.menu-wrapper-inner ul {
  list-style-type: none;
}

.menu-wrapper-inner ul li:hover {
  background: #fa5c00;
}

.scrollable-list {
  margin: 0;
  padding: 0;
  font-size: 30px;
  line-height: 54px;
}

.scrollable-list-item {
  display: flex;
  height: 54px;
}

.scrollable-list-item em {
  display: flex;
  font-style: normal;
  font-size: 30px;
  padding-left: 20px;
  align-items: center;
}

.scrollable-list-item abbr {
  width: auto;
  padding-left: 20px;
  font-size: 30px;
}

.TV-list-body {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  text-align: left;
  margin: 0;
  padding: 0;
  top: -31px;
}

.Tv-list-text {
  line-height: 30px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  padding-left: 16px;
  margin: 0;
}

.Tv-list {
  display: block;
  margin-left: 315px;
  width: 350px;
  top: 0;
  left: 0;
}

.Tv-list-inner {
  width: 100%;
  margin-left: 0;
  padding-left: 0;
}

.Tv-list ul li:hover {
  background: gray;
}

.Tv-list ul li::before {
  content: " ";
  position: absolute;
  border: 8px solid #32BA1B;
  border-radius: 30px;
  right: 15px;
  top: 27px;
  z-index: 10;
}

.Tv-list ul li:active {
  background: #fa5c00;
}

.tv-list-wrapper {
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
}


